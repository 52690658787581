import React,{useEffect} from 'react'
import { Switch, Route } from 'react-router-dom'
import ReactGA from 'react-ga'
import './assets/main.css'

import HomePage from './pages/homepage.component'
import AboutPage from './pages/aboutpage.component'
import Footer from './components/footer.component'
import Header from './components/header.component'
import SolutionsPage from './pages/solutions.component'
import ClientsPage from './pages/clients.component.jsx'
import ContactsPage from './pages/contact-us.component'

function App() {
 
  useEffect(() => {
    ReactGA.initialize('UA-173520012-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
    <div>
      <Header />
      <Switch >
        <Route exact path='/' component={HomePage} />
        <Route exact path='/about-us' component={AboutPage} />
        <Route exact path='/solutions' component={SolutionsPage} />
        <Route exact path='/clients' component={ClientsPage} />
        <Route exact path='/contact-us' component={ContactsPage} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
