import React, {useState}from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';


const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  return(
//style={{background: 'linear-gradient(120deg, #fff 39%, #7b27ab 39%, #ff8b00 100% )'}}
  <header  className="bg-white navbar fixed px-8 w-full shadow z-10 md:flex sm:justify-between md:items-center sm:px-16 sm:py-3">
    <div className="flex items-center justify-between px- py-3 sm:p-0">

      <div className='bg-white p-2 lg:bg-opacity-0'>
        <Logo className="h-12 sm:h-16 py-1 " alt="Staffing Africa" />
      </div>

      <div className="md:hidden">
        <button onClick={()=>setIsOpen(!isOpen)} type="button" className="block text-logoOrange2 hover:text-logoPuple focus:text-logoPuple focus:outline-none">
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
          {
            isOpen ? 
            <path  fillRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
            :
            <path  fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
          }
            
            
          </svg>
        </button>
      </div>

    </div>
    <nav className={`${isOpen ? 'block' : 'hidden'} px-2 pt-2 pb-4 md:flex sm:p-0 text-logoPurple2`}>

      <Link to="/" onClick={()=>setIsOpen(!isOpen)} className="block px-4 sm:px-1 py-1  uppercase tracking-wide font-medium text-xs hover:text-black hover:underline sm:text-xs lg:text-sm sm:ml-1 lg:px-4 ">Home</Link>

      <Link to="/about-us" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1  uppercase tracking-wide font-medium text-xs hover:text-black hover:underline sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm lg:px-4 ">About Us</Link>

      {/* <Link to="/advantages" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1  uppercase tracking-wide font-medium text-xs hover:text-black hover:underline sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm lg:px-4 ">Advantages</Link> */}

      <Link to="/solutions" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1  uppercase tracking-wide font-medium text-xs hover:text-black hover:underline sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm lg:px-4 ">Solutions</Link>

      

      <Link to="/clients" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1  uppercase tracking-wide font-medium text-xs hover:text-black hover:underline sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm lg:px-4 ">Clients</Link>

      <Link to="/contact-us" onClick={()=>setIsOpen(!isOpen)} className="mt-1 block px-4 sm:px-1 py-1  uppercase tracking-wide font-medium text-xs hover:text-black hover:underline sm:mt-0 sm:ml-1 sm:text-xs lg:text-sm lg:px-4  ">Contact Us</Link>

    </nav>
  </header>
  
)};

export default Header;
