import React from 'react';
import {Helmet} from "react-helmet"
import Bg from '../assets/bg-1.jpg'

const SolutionsPage = () => (
<>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Manpower Networks - Solutions</title>
        <link rel="canonical" href="https://manpowernetworks.co.ke/solutions" />
        <meta name="description" content="We provide and manage qualified and suitable staff to meet corporate short and long term labour requirements" />
    </Helmet>
    <div className='bg-fixed h-64 sm:h-80 md:h-96 lg:h-112 xl:h-128' style={{backgroundImage: `url(${Bg})`, display: 'inline-block',
    width: '100%',
    backgroundSize: '100%',
    backgroundPosition: '100% 18%', backgroundRepeat: 'no-repeat'}}>
        
    </div>

    <div className='container mx-auto '>
        <div className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white 
        -mt-20 sm:-mt-24 md:-mt-32 lg:-mt-40 px-10 sm:px-20 lg:px-24 py-2'>
            Our Solutions
        </div>

        <div className='flex flex-wrap justify-between bg-green-200  px-4 py-12 sm:px-16 sm:py-10 lg:px-16 lg:py-20'>
            

            <div className='w-full lg:w-1/2 px-6'>
                <div className=''>
                    <h1 className='py-3 sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium  '>Recruitment <span className='font-light'>Division</span></h1>
                    <div className='pb-10 text-gray-600 font-medium leading-relaxed '>At Manpower Networks, recruitment means ensuring the 'Best Fit'.
We take time to understand our clients' needs, the competencies, skills, experiences, aptitude required for the position in order to create the most suitable match.
This enables us to match client's needs with job seekers experience, achievements, aptitude, aspirations, and track record.
<br/><br/>We counsel our job seekers about our client's business, industry, and their expectations. These include Accountants, IT Analysts/Programmers, Customer care staff, Supervisors, Secretaries, Sales staff, Cashiers, Clerks, Drivers, Messengers.</div>

                </div>

            </div>
            <div className='w-full lg:w-1/2 px-6'>
                <div className='px-10 py-4 lg:px-10  lg:pt-16'>
                    <div className='pb-10 text-green-900 font-medium text-lg leading-relaxed '>
                    By outsourcing your recruitment processing to us, you will realize a number of advantages;
- Your hiring process is much easier and quicker, allowing you to bring new employees onboard faster than you previously did.
- We have access to a large pool of applicants, meaning you will get more qualified candidates applying for your jobs.
- We also provide you with better reports, reducing the amount of money you spend on recruiting, and make it easier for you to keep in compliance with the government regulations.
                    </div>
                
                 
                </div>
            </div>
        </div>

        <div className=' flex flex-wrap justify-between px-4 py-12 sm:px-16 sm:py-10 lg:px-16 lg:py-20'>
            <div className='w-full lg:w-1/2 px-6'>
                <div className='lg:pt-12'>

                    <h1 className='py-3 sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium  leading-none'>Outsourced Manpower <span className='font-light'>Division</span></h1>
                    <div className='pb-10 text-gray-600 font-medium leading-relaxed '>In this division, we recruit, train, place and manage staff in our clients offices and worksites. We can also take up existing casual labor pool and manage it with a view to making it more professional and productive.

i. Administration support staff such as book keepers, Computer operators, cashiers, clerks, receptionists, secretaries, drivers, messengers and cleaners.
ii. Marketing Support staff- Sales assistants, promotion staff, van drivers etc.
iii. Production Support staff- Production assistants, machine operators, packers, forklift drivers, dispatch assistants, warehouse assistants and general operatives. 
<br/><br/>The staff we manage in this arrangement remain our employees and hence we offer a number of HR-oriented services, including:</div>
                 
                </div>
            </div>

            <div className='w-full lg:w-1/2 px-6 order-first lg:order-last'>
                <div className='px-10 py-4 lg:px-10  lg:pt-16 text-green-800 text-sm'>
                
<br/><br/><span className='text-logoOrange2' >
Government compliance - </span> We will help your business keep current with the ever changing employment-related government regulations.
<br/><br/><span className='text-logoOrange2' >
Payroll & employee administration - </span> We will legally take on the responsibility of payroll, payroll taxes, quarterly reports, employment confirmation and human resource management reports. In partnering with us for these responsibilities, you will have more time to devote to your business.
<br/><br/><span className='text-logoOrange2' >
Recruitment & selection - </span> In close liaison with you, we will create job descriptions, source for employees, review resumes, test and interview job candidates, and conduct background checks on your behalf.
<br/><br/><span className='text-logoOrange2' >
Training & development - </span> We will analyze your training needs, and then provide the right courses to equip the workers with relevant skills to cope with the ever changing job demand. This is done in liaison with the client to ensure achieving excellent results, and to improve employee performance and productivity.

                </div>

            </div>
        </div>
        
    </div>

</>
)

export default SolutionsPage