import React from 'react'

const Client =({logo, services, name})=>(
<div className=" rounded-lg overflow-hidden shadow-lg bg-white">
    <img className="w-full" src={`/${logo}`} alt={`.${name}`}/>
    <div className="px-6 py-4 bg-green-200">
    <div className="font-medium h-auto text-2xl mb-2 text-logoPurple2 leading-6 text-center">{name}</div>
        <p className="text-green-900 text-sm text-center">{services}
        </p>
    </div>
</div>
)

export default Client

