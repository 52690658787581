import React from 'react';
import {Helmet} from "react-helmet"
import Bg from '../assets/bg-4.jpg'
import Client from '../components/Client.component'

const ClientsPage = () => (
<>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Manpower Networks - Clients</title>
        <link rel="canonical" href="https://manpowernetworks.co.ke/clients" />
        <meta name="description" content="Manpower Networks Ltd providies outsourced manpower services to various companys." />
    </Helmet>
    <div className='bg-fixed h-64 sm:h-80 md:h-96 lg:h-112 xl:h-128' style={{backgroundImage: `url(${Bg})`, display: 'inline-block',
    width: '100%',
    backgroundSize: '100%',
    backgroundPosition: '100% 18%', backgroundRepeat: 'no-repeat'}}>
        
    </div>

    <div className='container mx-auto'>
        <div className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white 
        -mt-20 sm:-mt-24 md:-mt-32 lg:-mt-40 px-10 sm:px-20 lg:px-24 py-2'>
            Our Clients
        </div>
        
        <div className='px-10 sm:px-20 lg:px-24  pt-12 sm:mt-5 md:mt-4 lg:mt-10 py-10 flex flex-wrap justify-center lg:justify-between bg-green-100'>
            <div className='w-full md:w-1/2 xl:w-1/3 justify-center'>
                <div className='px-2 py-2'>
                    <Client name='Beiersdorf E.A Ltd' services='' logo='bei.png' />
                </div>
            </div>
            <div className='w-full md:w-1/2 xl:w-1/3'>
                <div className='px-2 py-2'>
                    <Client name='Unga Limited' services='' logo='unga.png' />
                </div>
            </div>
            <div className='w-full md:w-1/2 xl:w-1/3'>
                <div className='px-2 py-2'>
                    <Client name='Wrigley E.A. Ltd' services='' logo='wrigley.png' />
                </div>
            </div> 

            <div className='w-full md:w-1/2 xl:w-1/3'>
                <div className='px-2 py-2'>
                    <Client name='Addax/Oryx Energies Kenya' services='' logo='addax.png' />
                </div>
            </div>

            <div className='w-full md:w-1/2 xl:w-1/3'>
                <div className='px-2 py-2'>
                    <Client name='Bamburi Cement' services='' logo='bamburi.png' />
                </div>
            </div>

            

            <div className='w-full md:w-1/2 xl:w-1/3'>
                <div className='px-2 py-2'>
                    <Client name='Brookhouse' services='' logo='brookhouse.png' />
                </div>
            </div>

            <div className='w-full md:w-1/2 xl:w-1/3'>
                <div className='px-2 py-2'>
                    <Client name='Oil Libya' services='' logo='oillybia.png' />
                </div>
            </div>
            <div className='w-full md:w-1/2 xl:w-1/3'>
                <div className='px-2 py-2'>
                    <Client name='Nas Airport Services' services='' logo='nas.png' />
                </div>
            </div>

            <div className='w-full md:w-1/2 xl:w-1/3'>
                <div className='px-2 py-2'>
                    <Client name='James Finlays' services='' logo='james.png' />
                </div>
            </div>

            <div className='w-full md:w-1/2 xl:w-1/3'>
                <div className='px-2 py-2'>
                    <Client name='Kenya Shell / Vivo Energy Limited' services='' logo='shell.png' />
                </div>
            </div>

        </div>
    </div>

</>
)

export default ClientsPage