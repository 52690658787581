import React from 'react';
import {Helmet} from "react-helmet"
import Flickity from 'react-flickity-component'
import Client from '../components/Client.component'
import { FiAlertOctagon, FiAward, FiActivity, FiCheckCircle, FiLink, FiRefreshCcw, FiBriefcase, FiShare2, FiCheck, FiHelpCircle, FiCircle, FiZap } from 'react-icons/fi'

const flickityOptions = {
     initialIndex: 0,
     fade: true,
     pauseAutoPlayOnHover: false,
     prevNextButtons: true,      
     autoPlay: 4000,
     wrapAround: true,
     fullscreen: true,
     adaptiveHeight: true,
     pageDots: false,
     imagesLoaded: true 
 }


const HomePage = () => (
  <>
     <Helmet>
          <meta charSet="utf-8" />
          <title>Manpower Networks - Home</title>
          <link rel="canonical" href="https://manpowernetworks.co.ke/" />
          <meta name="description" content="Manpower Networks is human resource Service Providers in recruitment and Supply of Outsourced Labour." />
     </Helmet>

     <div className='w-auto  h-72 sm:h-96 md:h-112 lg:h-144 xl:h-full pt-20 sm:pt-8 lg:pt-0  overflow-hidden'>
          <Flickity
               className={'carousel'} // default ''
               elementType={'div'} // default 'div'
               options={flickityOptions} // takes flickity options {}
               disableImagesLoaded={false} // default false
               reloadOnUpdate // default false
               static // default false
          >
               <div style={{ width: '100%', height: '650px'}}>
                    <img src='/slider.jpg' alt='Manpower Networks'/>
               </div>
               <div style={{ width: '100%', height: '650px'}}>
                    <img src='/slider2.jpg' alt='Manpower Networks'/>
               </div>
               
               
          </Flickity>
    </div>

    <div className='container mx-auto px-10 py-4 lg:px-16 lg:py-16 xl:px-20 xl:py-16 xl:pb-20' >

          <div className=''>
               <h1 className='sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium'>
                    Welcome to Manpower Networks
               </h1>
               <div className='flex flex-wrap pt-4'>
                    <div className='w-full xl:w-1/2'>
                    <p className='text-gray-600 text-base pb-2 pr-4'>
               Manpower Networks and sister Company Tropical Focus are human resource Service Providers in recruitment and Supply of Outsourced Labour.
<br/><br/>
The directors and key Personnel of Manpower Networks Ltd have extensive training and experience in human resource management. This has enabled us develop professionalism and apply service models that ensures compatibility with HR policies of our clients in the various organizations where we operate.
<br/><br/>
Over the years we have handled recruitment of staff and outsourcing projects with several multinationals and have been exposed to elaborate Health, Safety, Security & Environment (HSSE) and quality systems.
<br/><br/>
Further we endeavor to positively affect the management of temporary labour with a view to improving motivation and hence productivity. Our HR practices ensure that labour disputes are kept at minimum levels. Manpower Networks Ltd is a member of Federation of Kenya Employers (FKE) and Kenya Association of Private Employment Agencies (KAPEA).
               </p>
                    </div>
                    <div className='w-full xl:w-1/2'>
                         <img src={require('../assets/home.jpg')} alt='outsourced labor management' />
                    </div>
               </div>
               
          </div>


          <div className='flex flex-wrap pt-10 xl:pt-20'>

               <div className='w-full md:w-1/2 xl:w-1/3'>
                    <div className='px-2 py-2'>
                         <Client name='Our Business' services='Our Business is to provide comprehensive Human Resource Management Solutions to various corporate companies.' logo='03.jpg' />
                    </div>
               </div> 
               <div className='w-full md:w-1/2 xl:w-1/3'>
                    <div className='px-2 py-2'>
                         <Client name='Our Strategies' services='We believe in excellent product and service delivery to our customers, that surpasses their expectations.' logo='02.jpg' />
                    </div>
               </div> 
               <div className='w-full md:w-1/2 xl:w-1/3'>
                    <div className='px-2 py-2'>
                         <Client name='Why Us?' services='We are committed to promoting high standards of discipline, excellence and ethics through adherenc to global standards.' logo='01.jpg' />
                    </div>
               </div> 
          </div>
     

    </div>

  </>
);

export default HomePage;
